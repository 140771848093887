import {
  Editor,
  Range,
  Point,
  Path,
  Element,
  Node,
  Text,
  Transforms
} from "slate";
import { parseToMdxast, toSlateAst } from "@sector/mdx-editor/mdx-parse";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
const debug = require("debug")("@sector/mdx-editor:with-paragraphs");

export const withParagraphs = editor => {
  const {
    insertText,
    insertBreak,
    deleteForward,
    deleteBackward,
    deleteFragment,
    normalizeNode,
    isInline
  } = editor;

  const inlineElements = ["linkReference", "link"];
  editor.isInline = element => {
    return inlineElements.includes(element.type) || isInline(element);
  };
  editor.normalizeNode = entry => {
    debug("normalizeNode");
    const [node, path] = entry;
    if (Text.isText(node) && node.type === "text") {
      // try to merge any adjacent text nodes.
      // I'm not sure why Slate isn't handling this itself, as
      // described in the docs. It might be that Slate isn't
      // really expecting a tree as deep as ours can be with mdxast
      let hasPreviousPath = false;
      try {
        // we can't merge the first node into the non-existant previous node
        // (or at least don't want to try to), so we check to see if there's
        // a previous path first, which happens to throw if there isn't.
        Path.previous(path);
        hasPreviousPath = true;
      } catch (e) {}

      //   let hasNextPath = false;
      //   try {
      //     // we can't merge the first node into the non-existant next node
      //     // (or at least don't want to try to), so we check to see if there's
      //     // a previous path first, which happens to throw if there isn't.
      //     Path.next(path);
      //     hasNextPath = true;
      //   } catch (e) {}

      if (hasPreviousPath) {
        const [previousNode, prevPath] = Editor.previous(editor);
        if (
          !Path.equals(prevPath, path) &&
          Path.isSibling(prevPath, path) &&
          previousNode.type === "text"
        ) {
          // merge this text node into the previous, matching text node
          Transforms.mergeNodes(editor, { at: path });
          return;
        }
      }
    }
    // try to turn paragraphs into mdxast
    // if (Element.isElement(node) && node.type === "paragraph") {
    //   debug("normalizeParagraph");
    //   //   const test =  Node.texts(node)
    //   //   console.log('test' ,test)
    //   //   for (const [tNode, tPath] of test) {
    //   //       console.log('t', tNode.text)
    //   //   }
    //   // we're assuming that a paragraph has a text node in it
    //   let textNode;
    //   try {
    //     [textNode] = Editor.node(editor, path.concat([0]));
    //   } catch (e) {
    //     console.log("failed to get text child node");
    //   }

    //   if (Text.isText(textNode) && textNode.type === "text") {
    //       debug('made it')
    //     try {
    //       const mdxast = parseToMdxast(textNode.text);
    //       const mdxastSlate = toSlateAst(cloneDeep(mdxast));
    //       console.log("node", mdxastSlate.children);
    //       if (!isEqual(node, mdxastSlate.children[0])) {
    //         // Editor.withoutNormalizing(editor, e => {
    //           const children = Node.children(editor, path);
    //           for (const [child, childPath] of children) {
    //             Transforms.removeNodes(editor, { at: path.concat(0) });
    //           }
    //           console.log("inserting", mdxastSlate.children[0].children);
    //           mdxastSlate.children[0].children.forEach(node => {
    //             console.log("inserting one");
    //             editor.insertNode(node);
    //           });
    //           //   Transforms.insertNodes(editor, mdxastSlate.children[0].children, {
    //           //     at: path.concat(0)
    //           //   });
    //         // });
    //       }
    //     } catch (e) {
    //       debug("couldnt parseToMdxast", e);
    //     }

    //     return;
    //   }
    // }
    normalizeNode(entry);
  };

  return editor;
};
