import React from "react";
import { Auth0Provider, useAuth0 } from "./src/auth0";
import { navigate } from "@reach/router";
import { setContext } from "apollo-link-context";
import { Global } from "@emotion/core";
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  concat
} from "@apollo/client";

const onRedirectCallback = appState => {
  navigate(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
  // history.push(
  //   appState && appState.targetUrl
  //     ? appState.targetUrl
  //     : window.location.pathname
  // );
};

export const wrapRootElement = ({ element }) => (
  <div>
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      client_id={process.env.GATSBY_AUTH0_CLIENT_ID}
      redirect_uri={process.env.GATSBY_AUTH0_REDIRECT_URI}
      audience={process.env.GATSBY_AUTH0_AUDIENCE}
      onRedirectCallback={onRedirectCallback}
    >
      <MakeApollo>{element}</MakeApollo>
    </Auth0Provider>
  </div>
);

const MakeApollo = ({ children }) => {
  const { getTokenSilently } = useAuth0();
  const asyncAuthLink = setContext(async (request, { headers = {} }) => {
    const token = await getTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null
      }
    };
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(
      asyncAuthLink,
      new HttpLink({
        uri: "https://api.sector.dev/graphql"
      })
    )
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
