/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState } from "react";
import cloneDeep from "lodash.clonedeep";

import Editor from "..";
import { parseToMdxast, toSlateAst, fromSlate } from "../mdx-parse";

const demoContent = {
  heading: parseToMdxast(`# some thing

and stuff`),
  code: parseToMdxast(`# A heading

    const thing = {}

some stuff`),
  tables: parseToMdxast(`# Some tables

| Tables   |      Are      |  Cool |
|----------|:-------------:|------:|
| col 1 is |  left-aligned | $1600 |
| col 2 is |    centered   |   $12 |
| col 3 is | right-aligned |    $1 |

it's up there ^`),
  lists: parseToMdxast(`# Some Lists

* an 
* unordered
* list

1. an
1. ordered
1. list

- another
- unordered
- list`),
  blockquotes: parseToMdxast(`# quotes

> something

> a double
> line quote`)
};
const EditorExample = props => {
  // content switching doesn't work
  const [demoKey, setDemoContent] = useState("heading");
  const [value, setValue] = useState();
  return (
    <div>
      <div>
        <ul>
          {Object.keys(demoContent).map(key => (
            <button
              onClick={e => {
                setDemoContent(key);
              }}
            >
              {key}
            </button>
          ))}
        </ul>
      </div>
      <div sx={{ display: "flex" }}>
        <Editor
          sx={{ flex: 1 }}
          mdast={demoContent[demoKey]}
          onChange={val => {
            setValue(cloneDeep(val));
          }}
        />
      </div>
      <div sx={{ display: "flex" }}>
        <div sx={{ flex: 1, maxWidth: "50%" }}>
          <h2>mdxast-slate</h2>
          <pre>{JSON.stringify(value, null, 2)}</pre>
        </div>
        <div sx={{ flex: 1, maxWidth: "50%" }}>
          <h2>mdxast</h2>
          <pre>
            {value &&
              JSON.stringify(
                fromSlate({ type: "root", children: cloneDeep(value) }),
                null,
                2
              )}
          </pre>
        </div>
      </div>
    </div>
  );
};
export default EditorExample;
