import { Editor, Transforms, Range, Point } from "slate";

export const withPasting = editor => {
  const { insertData } = editor;

  editor.insertData = data => {
    const [parent, path] = Editor.parent(editor, editor.selection);

    const text = data.getData("text/plain");
    if (text && parent.type === "code") {
      editor.insertText(text);
      return;
    }

    insertData(data);
  };

  return editor;
};
