import React from "react";

import Layout from "./layout";

export default ({ children }) => {
  return (
    <Layout>
      <main
        sx={{
          variant: "styles.containerNarrow"
        }}
      >
        {children}
      </main>
    </Layout>
  );
};
