/** @jsx jsx */
import React, { useMemo, useCallback, useState, useContext } from "react";
// import { jsx } from "./context";
import { jsx } from "theme-ui";
import isHotkey from "is-hotkey";
// Import the Slate editor factory.
import { createEditor, Text } from "slate";
import Prism from "prismjs";
import "prismjs/components/prism-markdown.js";

// Import the Slate components and React plugin.
import { Slate, Editable, withReact } from "slate-react";
import cloneDeep from "lodash.clonedeep";

import {
  withPasting,
  withHeadings,
  withCode,
  withTables,
  withLists,
  withBlockquotes,
  withParagraphs
} from "./extensions";
import { toSlateAst, parseToMdxast } from "./mdx-parse";

// // function onKeyDown(e) {
// //   if (isSaveHotkey(e)) {
// //     ...
// //   }
// // }
// const isSaveHotkey = isHotkey('mod+s')
const isCommandBarHotkey = isHotkey("mod+;");
const isShiftEnter = isHotkey("shift+enter");

const defaultValue = parseToMdxast(`
# title

and some content
`);

const renderElement = props => {
  // console.log("renderElement", props.element.type);
  switch (props.element.type) {
    case "heading":
      const { type, depth } = props.element;

      return jsx(
        `h${depth}`,
        {
          ...props,
          sx: {
            fontFamily: "body",
            color: "text",
            margin: 0,
            marginTop: 4
          }
        },
        props.children
      );
    case "code":
      return (
        <pre>
          <code {...props} />
        </pre>
      );
    case "blockquote":
      return (
        <blockquote
          {...props}
          sx={{
            borderLeft: "3px solid black",
            margin: 0,
            paddingRight: 3,
            paddingLeft: 3,
            marginTop: 1
          }}
        />
      );
    case "import":
    case "export":
      return (
        <pre>
          <code {...props} />
        </pre>
      );
    case "paragraph":
      return <p {...props} sx={{ margin: 0, marginTop: 3 }} />;
    case "table":
      return (
        <div>
          <div sx={{ display: "flex" }}>
            <table
              {...props}
              sx={{
                borderCollapse: "collapse",
                boxShadow: `
          0 2.8px 2.2px rgba(0, 0, 0, 0.02),
          0 6.7px 5.3px rgba(0, 0, 0, 0.028),
          0 12.5px 10px rgba(0, 0, 0, 0.035),
          0 22.3px 17.9px rgba(0, 0, 0, 0.042),
          0 41.8px 33.4px rgba(0, 0, 0, 0.05),
          0 100px 80px rgba(0, 0, 0, 0.07)
        `,
                ...props.element.align
                  .map((val, i) => {
                    if (!Boolean(val)) {
                      return null;
                    }
                    return {
                      [`& td:nth-of-type(${i + 1})`]: {
                        textAlign: val
                      }
                    };
                  })
                  .filter(v => Boolean(v))
                  .reduce((acc, obj) => ({ ...acc, ...obj }), {})
              }}
            />
            <div>
              <button>Add Column</button>
            </div>
          </div>
          <div>
            <button>Add Row</button>
          </div>
        </div>
      );
    case "tableRow":
      if (props.element.isHead) {
        return <TableHeader {...props} />;
      } else {
        return (
          <tbody>
            <tr {...props} />
          </tbody>
        );
      }

    case "tableCell":
      return <TableHeading {...props} />;
    case "list":
      const ListType = Boolean(props.element.ordered) ? "ol" : "ul";
      return <ListType {...props} />;
    case "listItem":
      const isCheckedBox = props.element.checked;
      return <li {...props} />;
    case "linkReference":
      return <span {...props} />;
    default:
      return <div {...props} />;
  }
};
const TableContext = React.createContext({ isHead: false });
const TableHeader = props => {
  return (
    <TableContext.Provider value={{ isHead: true }}>
      <thead>
        <tr {...props} />
      </thead>
    </TableContext.Provider>
  );
};
const TableHeading = props => {
  const { isHead } = useContext(TableContext);
  if (isHead) {
    return (
      <th
        {...props}
        sx={{
          padding: 2,
          backgroundColor: "#efeff6",
          border: "1px solid #d6d6fb"
        }}
      />
    );
  }
  return <td {...props} sx={{ padding: 2, border: "1px solid #d6d6fb" }} />;
};
const renderLeaf = props => {
  const { attributes, children, leaf } = props;
  // console.log("leaf", leaf);
  return <Leaf {...props}>{children}</Leaf>;
};
const Leaf = props => {
  if (props.leaf.url) {
    return (
      <span
        {...props.attributes}
        sx={{
          color: "text"
        }}
      >
        <a href={props.leaf.href} title={props.leaf.linkText}>
          {props.children}
        </a>
      </span>
    );
  }
  return (
    <span
      {...props.attributes}
      sx={{
        fontWeight: props.leaf.bold ? "bold" : "normal",
        color: "text"
      }}
    >
      {props.children}
    </span>
  );
};

const RawMDXEditor = props => {
  const editor = useMemo(() => {
    if (Boolean(props.with)) {
      return props.with(
        withTables(
          withLists(
            withBlockquotes(
              withCode(
                withHeadings(
                  withParagraphs(withPasting(withReact(createEditor())))
                )
              )
            )
          )
        )
      );
    }
    return withParagraphs(
      withTables(
        withLists(
          withBlockquotes(
            withCode(withHeadings(withPasting(withReact(createEditor()))))
          )
        )
      )
    );
  }, [props.with]);
  const [value, setValue] = useState(
    props.mdast
      ? toSlateAst(cloneDeep(props.mdast)).children
      : toSlateAst(cloneDeep(defaultValue)).children
  );
  console.log("children", value);
  const decorate = useCallback(([node, path]) => {
    const ranges = [];

    if (!Text.isText(node)) {
      return ranges;
    }

    const getLength = token => {
      if (typeof token === "string") {
        return token.length;
      } else if (typeof token.content === "string") {
        return token.content.length;
      } else {
        return token.content.reduce((l, t) => l + getLength(t), 0);
      }
    };

    const getLinkAttributes = (token, rangeData) => {
      const linkText = token.content[1].content[0];
      const href = token.content[2].replace(/^\]\(/, "").replace(/\)$/, "");

      // Taking a page from IA Writer's book, we offset the
      // actual range for the url selection to only highlight
      // the url. Since this is only decoration for rendering
      // in the editor, and doesn't affect the written link
      // syntax, it should be a decent stop gap for the time.
      //
      //   • increment the starting offset by the length of text
      //     and the preceding "[FOO](" before the url.
      //   • decrement the ending offset to account for the
      //     closing paren.
      rangeData.anchor.offset += linkText.length + 3;
      rangeData.focus.offset--;

      return {
        [token.type]: true,
        linkText,
        href,
        ...rangeData
      };
    };

    const tokens = Prism.tokenize(node.text, Prism.languages.markdown);
    let start = 0;

    for (const token of tokens) {
      const length = getLength(token);
      const end = start + length;

      const rangeData = {
        anchor: { path, offset: start },
        focus: { path, offset: end }
      };

      if (token.type === "url") {
        ranges.push(getLinkAttributes(token, rangeData));
      } else if (typeof token !== "string") {
        ranges.push({
          [token.type]: true,
          ...rangeData
        });
      }

      start = end;
    }

    return ranges;
  }, []);

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={mdxastSlate => {
        setValue(mdxastSlate);
        props.onChange && props.onChange(mdxastSlate);
      }}
    >
      <Editable
        decorate={decorate}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={event => {
          if (isShiftEnter(event)) {
            // shift+enter results in a newline, not an insertBreak
            editor.insertText("\n");
            event.preventDefault();
          }
        }}
      />
    </Slate>
  );
};

export default RawMDXEditor;

// Fallback Editor accepts mdxast and renders it as Slate would
export const FallbackEditor = ({ mdxast, ...props }) => {
  if (Array.isArray(mdxast)) {
    throw new Error("FallbackEditor expects an mdxast object, got an array");
  }
  const editor = useMemo(() => withReact(createEditor()), [mdxast]);
  const mdxastSlate = toSlateAst(cloneDeep(mdxast)).children;
  return (
    <Slate editor={editor} value={mdxastSlate}>
      <Editable renderElement={renderElement} readOnly />
    </Slate>
  );
};
