import { Editor, Range, Point, Element, Node, Transforms, Text } from "slate";
const debug = require("debug")("@sector/mdx-editor:with-blockquotes");

export const withBlockquotes = editor => {
  const {
    insertText,
    insertBreak,
    deleteForward,
    deleteBackward,
    deleteFragment,
    normalizeNode
  } = editor;

  editor.insertBreak = () => {
    debug("insertBreak");

    // selection access has to be inside this function or we get a stale value
    const { selection } = editor;

    // if the selection range is collapsed, and the parent is a blockquote, insert a paragraph instead of splitting the blockquote
    // TODO: add "if selection is at the last possible spot in the blockquote node text nodes"
    if (!!selection && Range.isCollapsed(selection)) {
      const [parent, parentLoc] = Editor.parent(editor, selection);
      const [grandparent, grandparentLocation] = Editor.parent(
        editor,
        parentLoc
      );

      if (parent.type === "paragraph" && grandparent.type === "blockquote") {
        Transforms.insertNodes(
          editor,
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: ""
              }
            ]
          },
          { mode: "highest" }
        );
      } else {
        debug("insertBreak: grandparent type is not blockquote");
        // otherwise do whatever
        insertBreak();
      }
    } else {
      debug("insertBreak: selection `%s`", selection);
      // otherwise do whatever
      insertBreak();
    }
  };

  editor.insertText = text => {
    insertText(text);
    // after text is inserted, check to see how many hashes there are and change depth accordingly
    if (
      // # is the keycode for markdown headings
      text === ">" &&
      // idk if selection is ever falsey
      !!editor.selection &&
      // start and end of selection are the same spot
      Range.isCollapsed(editor.selection) &&
      // is the beginning of line
      Range.start(editor.selection).offset < 2
    ) {
      Transforms.wrapNodes(editor, {
        type: "blockquote"
      });
    }
  };

  editor.normalizeNode = entry => {
    debug("normalizeNode");

    const [node, path] = entry;
    // If the element is a blockquote, ensure the first text block
    // starts with `>`
    if (Element.isElement(node) && node.type === "blockquote") {
      // we're assuming that a blockquote has a paragraph child with a text node in it
      const [textNode] = Editor.node(editor, path.concat([0, 0]));

      if (Text.isText(textNode) && textNode.type === "text") {
        // if the text node doesn't start with `>`, then unwrap it,
        // making it a paragraph
        if (!textNode.text.startsWith(">")) {
          Transforms.unwrapNodes(editor, { at: path });
          return;
        }
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };

  return editor;
};
