export default {
  useCustomProperties: true,
  initialColorMode: "system",
  colors: {
    text: "#000",
    textMuted: "#3e424d",
    background: "#F1F7FA",
    sheet: "white",
    primary: "#33e",
    secondary: "#119",
    muted: "#f6f6f6",
    highlight: "#efeffe",
    gray: "#777",
    accent: "#609",
    note: "#078c35",
    blogPost: "#064d74",
    modes: {
      dark: {
        text: "#fff",
        textMuted: "#fafafa",
        background: "#060606",
        sheet: "#121212",
        primary: "#3cf",
        secondary: "#e0f",
        muted: "#191919",
        highlight: "#29112c",
        gray: "#999",
        accent: "#c0f"
      },
      deep: {
        text: "hsl(210, 50%, 96%)",
        textMuted: "hsl(210, 50%, 96%)",
        background: "hsl(230, 25%, 18%)",
        sheet: "#121212",
        primary: "hsl(260, 100%, 80%)",
        secondary: "hsl(290, 100%, 80%)",
        highlight: "hsl(260, 20%, 40%)",
        accent: "hsl(290, 100%, 80%)",
        muted: "hsla(230, 20%, 0%, 20%)",
        gray: "hsl(210, 50%, 60%)"
      }
    }
  },
  shadows: [
    `none`,
    `0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028),
    0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042),
    0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07)`
  ],
  fonts: {
    body: "system-ui, sans-serif",
    heading: "inherit",
    monospace: "Menlo, monospace"
  },
  radii: [0, 5],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  fontWeights: {
    body: 400,
    heading: 700,
    display: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25
  },
  textStyles: {
    body: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body"
    },
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading"
    },
    display: {
      variant: "textStyles.heading",
      fontSize: [5, 6],
      fontWeight: "display",
      letterSpacing: "-0.03em",
      mt: 3
    }
  },
  styles: {
    container: {
      p: 3,
      maxWidth: 1024,
      mx: "auto"
    },
    containerNarrow: {
      variant: "styles.container",
      maxWidth: 680
    },
    root: {
      variant: "textStyles.body"
    },
    h1: {
      variant: "textStyles.display"
    },
    h2: {
      variant: "textStyles.heading",
      fontSize: 5
    },
    h3: {
      variant: "textStyles.heading",
      fontSize: 4
    },
    h4: {
      variant: "textStyles.heading",
      fontSize: 3
    },
    h5: {
      variant: "textStyles.heading",
      fontSize: 2
    },
    h6: {
      variant: "textStyles.heading",
      fontSize: 1
    },
    p: {
      variant: "textStyles.body",
      color: "textMuted",
      fontSize: [2, 3, 3]
    },
    a: {
      color: "text",
      "&:hover": {
        color: "primary"
      }
    },
    pre: {
      fontFamily: "monospace",
      fontSize: 1,
      p: 3,
      color: "text",
      bg: "muted",
      overflow: "auto",
      code: {
        color: "inherit"
      }
    },
    code: {
      fontFamily: "monospace",
      fontSize: 1
    },
    inlineCode: {
      fontFamily: "monospace",
      color: "secondary",
      bg: "muted"
    },
    table: {
      width: "100%",
      my: 4,
      borderCollapse: "separate",
      borderSpacing: 0,
      [["th", "td"]]: {
        textAlign: "left",
        py: "4px",
        pr: "4px",
        pl: 0,
        borderColor: "muted",
        borderBottomStyle: "solid"
      }
    },
    th: {
      verticalAlign: "bottom",
      borderBottomWidth: "2px"
    },
    td: {
      verticalAlign: "top",
      borderBottomWidth: "1px"
    },
    hr: {
      border: 0,
      borderBottom: "1px solid",
      borderColor: "muted"
    },
    sheet: {
      background: "white",
      boxShadow: 1,
      borderRadius: 1
    }
  }
};
