import React from "react";
import { Global } from "@emotion/core";

export const wrapRootElement = ({ element }) => {
  return (
    <div>
      <Global
        styles={{
          html: {
            boxSizing: "border-box"
          },
          body: { margin: 0, padding: 0 },
          "*": {
            boxSizing: "inherit"
          },
          "*:before": {
            boxSizing: "inherit"
          },
          "*:after": {
            boxSizing: "inherit"
          }
        }}
      />
      {element}
    </div>
  );
};
