import { Editor, Range, Point, Element, Node, Text, Transforms } from "slate";
const debug = require("debug")("@sector/mdx-editor:with-headings");

export const withHeadings = editor => {
  const {
    insertText,
    insertBreak,
    deleteForward,
    deleteBackward,
    deleteFragment,
    normalizeNode
  } = editor;

  editor.insertText = text => {
    insertText(text);
    // after text is inserted, check to see how many hashes there are and change depth accordingly
    if (
      // # is the keycode for markdown headings
      text === "#" &&
      // idk if selection is ever falsey
      !!editor.selection &&
      // start and end of selection are the same spot
      Range.isCollapsed(editor.selection) &&
      // is the beginning of line
      Range.start(editor.selection).offset < 7
    ) {
      ensureHeadingDepth(editor);
    }
  };

  editor.normalizeNode = entry => {
    debug("normalizeNode");

    const [node, path] = entry;
    // If the element is a heading, ensure the first text block
    // starts with `>`
    if (Element.isElement(node) && node.type === "heading") {
      // we're assuming that a heading has a text node in it
      const [textNode] = Editor.node(editor, path.concat([0]));

      if (Text.isText(textNode) && textNode.type === "text") {
        // if the text node doesn't start with `>`, then unwrap it,
        // making it a paragraph
        const [startingHashes, ...nothing] = textNode.text.match(/^#*/g);
        debug("performing normalization");
        if (startingHashes) {
          Transforms.setNodes(editor, {
            type: "heading",
            depth: startingHashes.length
          });
        } else {
          Transforms.setNodes(editor, {
            type: "paragraph"
          });
        }
        return;
      }
    }
    normalizeNode(entry);
  };

  return editor;
};

function ensureHeadingDepth(editor) {
  const [maybeHeading] = Editor.above(editor);
  const [textNode] = Editor.node(editor, editor.selection.anchor.path);
  // inserting or removing # at the beginning of a line affects paragraphs and heading elements
  if (
    (maybeHeading.type === "heading" || maybeHeading.type === "paragraph") &&
    textNode.type === "text"
  ) {
    const [startingHashes, ...nothing] = textNode.text.match(/^#*/g);
    if (startingHashes) {
      Transforms.setNodes(editor, {
        type: "heading",
        depth: startingHashes.length
      });
    } else {
      Transforms.setNodes(editor, {
        type: "paragraph"
      });
    }
  }
}
