import { Editor, Element, Range, Path, Point, Transforms, Text } from "slate";
import { parseToMdxast } from "@sector/mdx-editor/mdx-parse";
const debug = require("debug")("@sector/mdx-editor:with-code");

export const withCode = editor => {
  const {
    insertText,
    insertBreak,
    deleteForward,
    deleteBackward,
    deleteFragment,
    normalizeNode
  } = editor;

  editor.insertBreak = () => {
    debug("insertBreak");

    // selection access has to be inside this function or we get a stale value
    const { selection } = editor;

    // if
    //    the selection range is collapsed
    // && the parent is a code block
    // && the selection is *after* the ```
    // insert a paragraph instead of splitting a code block
    //
    // TODO: add "if selection is at the last possible spot in the heading node text nodes"
    if (!!selection && Range.isCollapsed(selection)) {
      const [node, nodeLocation] = Editor.node(editor, selection);
      const [parent, parentLocation] = Editor.parent(editor, selection);
      if (parent.type === "code" && Text.isText(node)) {
        // test to see if we're *after* the ending ```
        // console.log("insertBreak inside a code text node", node, parent);
        if (
          node.text.endsWith("\n```") &&
          Range.end(selection).offset === node.text.length
        ) {
          Editor.insertNode(editor, {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: ""
              }
            ]
          });
        } else {
          insertText("\n");
        }
      } else {
        debug("insertBreak: parent type is not code block");
        // otherwise do whatever
        insertBreak();
      }
    }
  };

  editor.insertText = text => {
    // console.log("text", text);
    insertText(text);

    const [node] = Editor.node(editor, editor.selection);
    const [parent] = Editor.above(editor, editor.selection);
    if (parent.type === "code") {
      const { children } = parseToMdxast(node.text);
      const { meta, lang } = children[0];
      Transforms.setNodes(editor, {
        meta,
        lang
      });
    } else if (
      Text.isText(node) &&
      node.text.startsWith("```") &&
      parent.type !== "code"
    ) {
      //   console.log(node.text);
      if (node.text === "```") {
        Transforms.setNodes(editor, {
          type: "code"
        });
        insertText("\n```");
      }
      console.log(
        `first: ${node.text.startsWith("```")} end: ${node.text.endsWith(
          "```"
        )}`
      );
      console.log("should maybe be a code block");
    }
  };

  // editor.normalizeNode = entry => {
  //   const [node, path] = entry;
  //   if (Element.isElement(node) && node.type === "code") {
  //     // try to merge any adjacent code nodes.
  //     let hasPreviousPath = false;
  //     try {
  //       // we can't merge the first node into the non-existant previous node
  //       // (or at least don't want to try to), so we check to see if there's
  //       // a previous path first, which happens to throw if there isn't.
  //       Path.previous(path);
  //       hasPreviousPath = true;
  //     } catch (e) {}
  //     if (hasPreviousPath) {
  //       const [previousNode, prevPath] = Editor.previous(editor);
  //       if (
  //         !Path.equals(prevPath, path) &&
  //         Path.isSibling(prevPath, path) &&
  //         previousNode.type === "code"
  //       ) {
  //         // merge this text node into the previous, matching text node
  //         Transforms.mergeNodes(editor, { at: path });
  //         return;
  //       }
  //     }
  //   }

  //   normalizeNode(entry);
  // };

  return editor;
};
