// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---packages-gatsby-theme-sector-app-src-templates-app-js": () => import("./../../../packages/gatsby-theme-sector-app/src/templates/app.js" /* webpackChunkName: "component---packages-gatsby-theme-sector-app-src-templates-app-js" */),
  "component---packages-mdx-editor-playground-mdx": () => import("./../../../packages/mdx-editor/playground.mdx" /* webpackChunkName: "component---packages-mdx-editor-playground-mdx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-about-mdx": () => import("./../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-design-system-mdx": () => import("./../src/pages/design-system.mdx" /* webpackChunkName: "component---src-pages-design-system-mdx" */),
  "component---src-pages-privacy-mdx": () => import("./../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-terms-mdx": () => import("./../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */)
}

