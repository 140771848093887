import unified from "unified";
import remarkStringify from "remark-stringify";
import remarkParse from "remark-parse";
import remarkSqueezeParagraphs from "remark-squeeze-paragraphs";
import mdx from "remark-mdx";
import visit from "unist-util-visit";
const debug = require("debug")("@sector/mdx-editor:mdx-parse");

export const rawMdParser = unified()
  .use(remarkParse, {
    commonmark: true,
    position: false,
    footnotes: true
  })
  .use(remarkSqueezeParagraphs)
  .use(mdx, { footnotes: true });
//   .use(remarkInterleave)

const parser = unified()
  .use(remarkParse, {
    commonmark: true,
    position: false,
    footnotes: true
  })
  .use(remarkSqueezeParagraphs)
  .use(mdx, { footnotes: true })
  .use(options => mdxastToSlate);

export const mdxastToSlate = ast => {
  visit(ast, "text", function(node) {
    // `type` is not a slate field, but slate doesn't get mad at us for having it there while unified gets mad at us if we *don't*, so we keep `type`.
    node.text = node.value;
    delete node.value;
  });
  visit(ast, "code", function(node) {
    let infostring = "";
    if (node.lang) {
      infostring = infostring + node.lang;
    }
    if (node.meta) {
      infostring = infostring + " " + node.meta;
    }
    infostring = infostring + "\n";

    node.children = [{ text: "```" + infostring + node.value + "\n```" }];
  });
  visit(ast, "table", function(node) {
    if (node.children[0]) {
      node.children[0].isHead = true;
    }
  });
  visit(ast, "import", function(node) {
    node.children = [{ text: node.value }];
  });
  visit(ast, "export", function(node) {
    node.children = [{ text: node.value }];
  });
  visit(ast, "jsx", function(node) {
    node.children = [{ text: node.value }];
  });

  visit(ast, "heading", function(node) {
    if (node.children && node.children[0]) {
      if (node.children[0].type === "text") {
        // add # to headings, as they are removed in the mdx -> mdxast conversion
        node.children[0].text = `${"#".repeat(node.depth)} ${
          node.children[0].text
        }`;
      }
    }
  });
  // return ast;
};

export const parseMDX = md => parser.runSync(parser.parse(md));
export const parseToMdxast = md => rawMdParser.runSync(rawMdParser.parse(md));

export const slateToMdxast = ast => {
  // console.log("ast", JSON.stringify(ast, null, 2));
  visit(ast, "text", function(node) {
    node.value = node.text;
    delete node.text;
  });
  visit(ast, "code", function(node) {
    if (node.children && node.children[0]) {
      if (node.children[0].type === "text") {
        // remove ```\n and \n``` from beginning and end, as they are added
        // in the mdxast -> mdx conversion
        // cheekily, we just use `parseToMdxast" to get it done lol.
        const codeblockText = node.children[0].value;
        const { children } = parseToMdxast(codeblockText);
        Object.entries(children[0]).map(([k, v]) => {
          node[k] = v;
        });
      }
    }
    // node.value = codeblock;
    // delete node.children;
  });
  visit(ast, "import", function(node) {
    node.value = node.children[0].text;
  });
  visit(ast, "export", function(node) {
    node.value = node.children[0].text;
  });
  visit(ast, "jsx", function(node) {
    node.value = node.children[0].text;
  });
  visit(ast, "heading", function(node) {
    if (node.children && node.children[0]) {
      if (node.children[0].type === "text") {
        // remove # from headings, as they are added in the mdxast -> mdx conversion
        // protect the string if the beginning isn't what we expect it to be
        if (node.children[0].value.startsWith("#".repeat(node.depth))) {
          node.children[0].value = node.children[0].value
            .substr(node.depth)
            .trim();
        }
      }
    }
  });
  // console.log("ast2", JSON.stringify(ast, null, 2));

  // return ast;
};
const stringifier = unified()
  .use(remarkStringify, {
    bullet: "*",
    fences: true,
    footnotes: true
  })
  .use(remarkSqueezeParagraphs)
  .use(mdx, { footnotes: true })
  .use(options => slateToMdxast);

export const stringifyMDX = mdxast =>
  stringifier.stringify(stringifier.runSync(mdxast));

// export cons parseMDX = () => ""

const fromSlateAst = unified()
  .use(remarkSqueezeParagraphs)
  .use(options => slateToMdxast);
export const fromSlate = mdxast => fromSlateAst.runSync(mdxast);

const toSlate = unified()
  .use(remarkSqueezeParagraphs)
  .use(options => mdxastToSlate);
export const toSlateAst = mdxast => toSlate.runSync(mdxast);

const getCodeblockMeta = value => {};
