import { Editor, Range, Point, Transforms } from "slate";
const debug = require("debug")("@sector/mdx-editor:with-tables");

export const withTables = editor => {
  const {
    insertText,
    insertBreak,
    deleteForward,
    deleteBackward,
    deleteFragment
  } = editor;

  editor.insertBreak = () => {
    debug("insertBreak");

    // selection access has to be inside this function or we get a stale value
    const { selection } = editor;
    const [node, nodeLocation] = Editor.node(editor, selection);
    let cell;
    let parentLocation;
    try {
      const parent = Editor.parent(editor, selection);
      cell = parent[0];
      parentLocation = parent[1];
    } catch (e) {
      insertBreak();
      return;
    }

    if (cell.type === "tableCell") {
      const [row, rowLocation] = Editor.parent(editor, parentLocation);
      const [table, tableLocation] = Editor.parent(editor, rowLocation);

      const numRows = table.children.length;

      const tableRowCell = parentLocation[parentLocation.length - 1];
      const tableRow = parentLocation[parentLocation.length - 2];
      //   const tableInt = parentLocation[parentLocation.length - 3];
      //   Editor.nodes
      // console.table({
      //   table,
      //   tableRow,
      //   tableRowCell
      // });
      Array(numRows)
        .fill(1)
        .forEach((_, i) => {
          //   if (i !== tableRow) {
          Transforms.insertNodes(
            editor,
            {
              type: "tableCell",
              children: [{ type: "text", text: "" }]
            },
            { at: tableLocation.concat([i, tableRowCell + 1]) }
          );
          //   }
        });
      Transforms.select(editor, rowLocation.concat([tableRowCell + 1, 0]));
    } else {
      insertBreak();
    }
  };

  editor.deleteBackward = unit => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const cells = Editor.nodes(editor, {
        match: n => n.type === "tableCell"
      });
      for (const cell of cells) {
        if (cell) {
          const [, cellPath] = cell;
          const start = Editor.start(editor, cellPath);

          if (Point.equals(selection.anchor, start)) {
            return;
          }
        }
      }
    }

    deleteBackward(unit);
  };

  editor.deleteForward = unit => {
    console.log("deleteForward");
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      console.log("selection collapsed");
      const cells = Editor.nodes(editor, {
        match: n => n.type === "tableCell"
      });
      // iterates all cells in document
      for (const cell of cells) {
        if (cell) {
          console.log("isCell", cell);
          const [, cellPath] = cell;
          const end = Editor.end(editor, cellPath);
          if (Point.equals(selection.anchor, end)) {
            return;
          }
        }
      }
    }

    deleteForward(unit);
  };

  return editor;
};
