import { Editor, Range, Point, Transforms, Text, Node, Element } from "slate";
import { parseToMdxast } from "@sector/mdx-editor/mdx-parse";
const debug = require("debug")("@sector/mdx-editor:with-lists");

export const withLists = editor => {
  const {
    insertText,
    insertBreak,
    deleteForward,
    deleteBackward,
    deleteFragment,
    normalizeNode
  } = editor;

  editor.insertBreak = () => {
    debug("insertBreak");

    // selection access has to be inside this function or we get a stale value
    const { selection } = editor;

    // if
    // the parent.parent is a listItem
    // split the listItem, not the paragraph
    //
    // TODO: add "if selection is at the last possible spot in the heading node text nodes"
    // if (!!selection && Range.isCollapsed(selection)) {
    const [, nodeLocation] = Editor.node(editor, selection);
    const ancestors = Node.ancestors(editor, nodeLocation);
    let inserted = false;
    for (const [ancestor, ancestorPath] of ancestors) {
      if (ancestor.type === "listItem") {
        // if there is a selection of text
        // and the user hits "enter"
        // delete that segment of text
        if (!Range.isCollapsed(editor.selection)) {
          Transforms.delete(editor);
        }

        // re-select the node to get the update values at the selection
        const [node] = Editor.node(editor, selection);

        // this gets the text before the cursor, but we don't actually need that yet
        // const textBeforeCursor = node.text.slice(
        //   0,
        //   editor.selection.anchor.offset
        // );
        const textAfterCursor = node.text.slice(
          editor.selection.anchor.offset,
          node.text.length
        );

        const amountOfTextToDelete =
          node.text.length - editor.selection.anchor.offset;
        // if the length is 0, we're at the end of the text element and
        // deleting with a distance of 0 causes a weird bug where the entire
        // rest of the document gets deleted, yay!
        if (amountOfTextToDelete > 0) {
          Transforms.delete(editor, {
            at: editor.selection,
            distance: amountOfTextToDelete
          });
        }
        // to put the listItem before this element, use lastItem
        // to put it after this element, use lastItem + 1
        const firstPathItems = [...ancestorPath];
        const lastItem = firstPathItems.pop();

        // insert new listItem as a sibling of the current one
        Transforms.insertNodes(
          editor,
          {
            type: "listItem",
            children: [
              {
                type: "paragraph",
                children: [
                  {
                    type: "text",
                    text: textAfterCursor
                  }
                ]
              }
            ]
          },
          {
            at: firstPathItems.concat(lastItem + 1)
          }
        );
        // select the new node
        Transforms.select(editor, firstPathItems.concat(lastItem + 1));
        // collapse the new selection into a single point
        Transforms.collapse(editor);

        // make sure we don't call "insertBreak"
        inserted = true;
        break;
      }
    }
    if (!inserted) {
      insertBreak();
    }
  };

  editor.insertText = text => {
    insertText(text);

    const [node, nodePath] = Editor.node(editor, editor.selection);
    const [parent, parentLocation] = Editor.above(editor, editor.selection);
    const [ancestor] = Editor.above(editor, parentLocation);
    if (
      Text.isText(node) &&
      Range.isCollapsed(editor.selection) &&
      editor.selection.anchor.offset < 3 &&
      node.text.startsWith("* ") &&
      parent.type === "paragraph"
    ) {
      Transforms.wrapNodes(
        editor,
        {
          type: "listItem"
        },
        { at: parentLocation }
      );
      // normalization will wrap the listItem in a list
      Transforms.delete(editor, {
        at: editor.selection,
        distance: 2,
        unit: "character",
        reverse: true
      });
    }
  };

  editor.deleteBackward = unit => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const cells = Editor.nodes(editor, {
        match: n => n.type === "listItem"
      });
      for (const cell of cells) {
        if (cell) {
          const [node, nodePath] = cell;
          const start = Editor.start(editor, nodePath);

          if (Point.equals(selection.anchor, start)) {
            // "split above"
            const [listNode, listNodePath] = Editor.parent(editor, nodePath);
            if (listNode.children.length > 1) {
              Transforms.splitNodes(editor, { at: nodePath });
            }

            // find new paragraph node so we can "split below"
            const [newNode, newNodePath] = Editor.parent(
              editor,
              editor.selection
            );

            newNodePath.pop();
            const level = newNodePath.pop();

            // if there is sibling listItem after this one, split it out
            if (Node.has(editor, newNodePath.concat(level + 1))) {
              Transforms.splitNodes(editor, {
                at: newNodePath.concat(level + 1)
              });
            }

            //
            const [finalNode, finalNodePath] = Editor.parent(
              editor,
              editor.selection
            );
            const nodes = Node.ancestors(editor, finalNodePath);
            for (const [item, itemLoc] of nodes) {
              if (item && item.type === "list") {
                Transforms.unwrapNodes(editor, { at: itemLoc });
                Transforms.unwrapNodes(editor, { at: itemLoc });
                break;
              }
            }
            return;
          }
        }
      }
    }

    deleteBackward(unit);
  };

  editor.normalizeNode = entry => {
    debug("normalizeNode");
    // TODO: if two lists are next to each other with the same
    // properties, merge them into the same list
    const [node, path] = entry;

    if (Element.isElement(node) && node.type === "list") {
      // not sure this is ever called
      // if a list has no children, delete it.
      if (node.children.length === 0) {
        Transforms.delete(editor, { at: path });
        return;
      }
    }
    if (Element.isElement(node) && node.type === "listItem") {
      try {
        const [parent] = Editor.parent(editor, path);
        if (parent.type !== "list") {
          // if the parent node is not a list, we need it to be a list
          Transforms.wrapNodes(editor, { type: "list" }, { at: path });
        }
      } catch (e) {
        // if there is not parent for a listItem, wrap it in one
        Transforms.wrapNodes(editor, { type: "list" }, { at: path });
      }
      return;
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };
  return editor;
};
